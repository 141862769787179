import React from 'react';
import BasicLayout from 'src/components/layouts/BasicLayout';
import SEO from 'src/components/SEO';
import { H1 } from 'src/DesignSystem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignature, faLongArrowAltRight, faTools, faGem } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'gatsby';

export default function Tools(): JSX.Element {
  return (
    <BasicLayout>
      <SEO title="Tools" description="A variety of RPG Tools" />
      <div className="max-w-4xl p-4 mx-auto text-gray-900 mb-8">
        <H1><FontAwesomeIcon className="text-orange-400" size="sm" icon={faTools} /> Tools</H1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4">
          <div className="flex rounded">
            <div className="rounded-l bg-gray-800 text-4xl text-orange-400 text-center p-3 flex items-center">
              <FontAwesomeIcon className="align-middle" icon={faSignature} />
            </div>
            <div className="bg-orange-100 rounded-r border-gray-800 px-4 py-3 flex-grow flex flex-col items-start">
              <Link to="names" className="uppercase tracking-wide font-medium text-lg text-orange-900 hover:underline mb-2">
                Name Generators
              </Link>
              <p>
                A number of useful name generators from the tables in Appendix B
                of &quot;Xanathar&apos;s Guide to Everything.&quot;
              </p>
              <Link to="names" className="self-end bg-orange-300 rounded px-3 py-1 mt-2 hover:bg-orange-400">
                Go <FontAwesomeIcon icon={faLongArrowAltRight} />
              </Link>
            </div>
          </div>

          <div className="flex rounded">
            <div className="rounded-l bg-gray-800 text-4xl text-blue-400 text-center p-3 flex items-center">
              <FontAwesomeIcon className="align-middle" icon={faGem} />
            </div>
            <div className="bg-blue-100 rounded-r border-gray-800 px-4 py-3 flex-grow flex flex-col items-start">
              <Link to="names" className="uppercase tracking-wide font-medium text-lg text-blue-900 hover:underline mb-2">
                Random 5e Treasure
              </Link>
              <p>
                Generate treasure using the random tables in Chapter 7 of the &quot;Dungeon Master&apos;s Guide.&quot;
              </p>
              <Link to="treasure" className="self-end bg-blue-300 rounded px-3 py-1 mt-2 hover:bg-blue-400">
                Go <FontAwesomeIcon icon={faLongArrowAltRight} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </BasicLayout>
  );
}
